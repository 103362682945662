<template>
  <div>
    <highcharts :options="chartOptions" ref="lineCharts" :constructor-type="'chart'" ></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'

HighchartsMore(Highcharts)
export default {
  props: ['options', 'catchLegendEvents'],
  name: 'PointChart',
  components: {
    highcharts: Chart
  },
  data () {
    return {
      chartOptions: Highcharts.merge(this.options, {
        chart: {
          height: 350,
          type : 'area'
        },
        plotOptions: {
          series:{
            fillOpacity: 0.4,
            dataLabels: {
              enabled: true
            }
          }
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          title: {
            enabled: false,
            style: {
              fontSize: '20px'
            },
            rotation: 0,
            align: 'low',
            y: 40,
            x: -20

          },
          labels: {
            style: {
            }
          }

        },
        tooltip: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        xAxis: {
          type: 'category'
        },
        credits: { enabled: false },
        colors: ['#007bff'],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                enabled: false,
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
              },
              title: {
                verticalAlign: 'top',
                align: 'center',
                floating: false
              }
            }
          }]
        }
      })
    }
  },
}

</script>

<style scoped>

</style>
