<template>
  <div>
    <highcharts :options="chartOptions" ref="barCharts" :constructor-type="'chart'" ></highcharts>
  </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import HighchartsMore from 'highcharts/highcharts-more'

    HighchartsMore(Highcharts)
    export default {
        props: ['options', 'catchLegendEvents'],
        name: 'BarChart',
        components: {
            highcharts: Chart
        },
        data() {
            return {
                chartOptions: Highcharts.merge(this.options, {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    colors: ['#F9A12EFF', '#946593', '#1C768F', '#032539'],
                    title: {
                        text: ''
                    },
                    subtitle: {},
                    xAxis: {
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        allowDecimals: false,
                        title: {
                            text: '',
                            align: 'high'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    tooltip: {
                        enabled: false,
                        valueSuffix: ' millions'
                    },
                    plotOptions: {
                        series: {
                            pointWidth: 30
                        },
                        bar: {
                            groupPadding: 0.1,
                            dataLabels: {
                                enabled: true,
                                inside: true,
                                align: 'right',
                                style: {
                                    color: 'white',
                                    fontSize: '15px',
                                    textOutline: 0
                                }
                            }
                        }
                    },
                    legend: {
                        enabled: true
                    },
                    credits: {
                        enabled: false
                    },
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                plotOptions: {
                                    series: {
                                        pointWidth: 28
                                    },
                                    column: {
                                        groupPadding: 0.1
                                    }
                                }
                            }
                        }]
                    }
                })
            }
        }
    }
</script>
